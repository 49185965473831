<script setup lang="ts">
const props = defineProps<{
  title: string
  breadcrumbTitle?: string
}>()

const router = useRouter()

function goBack() {
  if (router.options.history.state.back) {
    router.go(-1)
  } else {
    router.push('/')
  }
}
</script>

<template>
  <div>
    <div class="hidden items-center lg:flex">
      <UButton
        icon="i-heroicons-arrow-left"
        size="md"
        color="white"
        @click="goBack"
      />

      <BaseBreadcrumb :title="props.breadcrumbTitle ?? props.title" />
    </div>

    <UCard class="my-5">
      <div class="flex items-center justify-between">
        <div class="overflow-hidden">
          <USkeleton v-if="!props.title" class="h-[32px] w-full max-w-xs" />
          <h1
            v-else
            class="mb-0 mr-4 truncate text-2xl font-bold text-gray-800"
          >
            {{ props.title }}
          </h1>
        </div>

        <div>
          <slot name="buttons"></slot>
        </div>
      </div>
    </UCard>
  </div>
</template>
