<script setup lang="ts">
const props = defineProps<{
  title: string
}>()

const routeNames = [
  {
    param: 'index',
    name: 'Dashboard',
  },
  {
    param: 'patients',
    name: 'Pacientes',
  },
  {
    param: 'calendar',
    name: 'Calendário',
  },
  {
    param: 'patient',
    name: 'Paciente',
  },
  {
    param: 'patients-add',
    name: 'Adicionar Paciente',
  },
  {
    param: 'patients-id-edit',
    name: 'Editar',
  },
  {
    param: 'patients-id-appointments',
    name: 'Marcações',
  },
  {
    param: 'patients-id-invoices',
    name: 'Faturação',
  },
  {
    param: 'patients-id-notes',
    name: 'Notas',
  },
  {
    param: 'invoices',
    name: 'Faturas',
  },
  {
    param: 'invoices-add',
    name: 'Emitir Documento',
  },
  {
    param: 'stock',
    name: 'Stock',
  },
  {
    param: 'reports',
    name: 'Relatórios',
  },
  {
    param: 'chat',
    name: 'Mensagens',
  },
  {
    param: 'staff',
    name: 'Staff',
  },
  {
    param: 'staff-add',
    name: 'Adicionar Staff',
  },
  {
    param: 'staff-id-edit',
    name: 'Editar',
  },
  {
    param: 'settings',
    name: 'Definições',
  },
  {
    param: 'settings-account',
    name: 'Definições de Conta',
  },
  {
    param: 'settings-clinic',
    name: 'Definições de Clínica',
  },
  {
    param: 'logout',
    name: 'Sair da Conta',
  },
]

const route = useRoute()
const router = useRouter()

const nav = computed(() => {
  let path = route.fullPath

  if (path.includes('?')) {
    path = path.split('?')[0]
  }

  if (!path.endsWith('/')) {
    path = `${path}/`
  }

  const params = path.slice(1).split('/')
  const crumbsNav = []

  let currentPath = ''

  for (const [i, param] of params.entries()) {
    if (i !== params.length - 1) {
      currentPath = `${currentPath}/${param}`

      const match = router.resolve(currentPath)

      if (match.name !== null) {
        if (match.name === 'patients-id' || match.name === 'staff-id') {
          crumbsNav.push({
            title: props.title,
            ...match,
          })
        } else {
          try {
            crumbsNav.push({
              title: routeNames.find((x) => x.param === match.name)?.name,
              ...match,
            })
          } catch {
            if (match.name !== undefined) {
              // eslint-disable-next-line no-console
              console.log(match)
            }
          }
        }
      }
    }
  }
  return crumbsNav
})

function showTitle(title: string | undefined) {
  if (title?.includes('undefined')) return false
  if (title && title.trim() !== '') return true
  return false
}
</script>

<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol v-if="nav.length > 0" class="ml-4 flex items-center space-x-2">
      <li>
        <NuxtLink to="/" class="flex text-gray-700 hover:text-gray-800">
          <UIcon
            name="i-heroicons-home-solid"
            class="size-5 shrink-0"
            aria-hidden="true"
          />
          <span class="sr-only">Home</span>
        </NuxtLink>
      </li>
      <li v-for="(crumb, index) in nav" :key="index">
        <div v-if="showTitle(crumb.title)" class="flex items-center">
          <UIcon
            name="i-heroicons-chevron-right-solid"
            class="size-5 shrink-0 text-gray-700"
            aria-hidden="true"
          />
          <NuxtLink
            :to="`${crumb.path}/`"
            class="ml-3 text-sm font-medium text-gray-700 hover:text-gray-800"
            :aria-current="route.fullPath === crumb.path ? 'page' : undefined"
          >
            {{
              route.fullPath === crumb.path && title !== null
                ? title
                : crumb.title
            }}
          </NuxtLink>
        </div>
      </li>
    </ol>
  </nav>
</template>
